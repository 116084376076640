/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./app.component";
import * as i3 from "@angular/fire/auth";
import * as i4 from "@angular/fire/database";
import * as i5 from "./services/messaging.service";
import * as i6 from "./services/update.service";
import * as i7 from "@angular/service-worker";
var styles_AppComponent = [];
var RenderType_AppComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AppComponent, data: { "animation": [{ type: 7, name: "fadeAnimation", definitions: [{ type: 1, expr: "* <=> *", animation: [{ type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }], options: { optional: true } }, { type: 11, selector: ":leave", animation: [{ type: 6, styles: { opacity: 1 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0 }, offset: null }, timings: "50ms" }], options: { optional: true } }, { type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "50ms" }], options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "header", [["class", "c-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "c-header__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Valley Conf '24"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["equipping, empowering and encouraging the local church"])), (_l()(), i0.ɵeld(6, 0, null, null, 3, "main", [["class", "c-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "section", [], [[24, "@fadeAnimation", 0]], null, null, null, null)), (_l()(), i0.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(9, 212992, [["o", 4]], 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 9).isActivated ? i0.ɵnov(_v, 9).activatedRoute : ""); _ck(_v, 7, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i0.ɵdid(1, 4374528, null, 0, i2.AppComponent, [i3.AngularFireAuth, i4.AngularFireDatabase, i1.Router, i5.MessagingService, i6.UpdateService, i7.SwPush, i0.ApplicationRef], null, null)], null, null); }
var AppComponentNgFactory = i0.ɵccf("app-root", i2.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
