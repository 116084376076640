export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyASRXfvAU_HElRCud9hPteale0VIjlirXY",
    authDomain: "valleywebconference.firebaseapp.com",
    databaseURL: "https://valleywebconference.firebaseio.com",
    projectId: "valleywebconference",
    storageBucket: "valleywebconference.appspot.com",
    messagingSenderId: "728028542307",
    appId: "1:728028542307:web:9db935e08cb7c123",
    vapidKey: "BMDp43neoGK1xFg9ysN-ioz_6OxPkrqSUDdQEDajGGpgELruQUqDGunB6Jr0L2E1VeJxKBqZRJVead_WkGTFvBw"
  }
};
