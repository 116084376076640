import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';

import { take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs'
import { SwPush, SwRegistrationOptions } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

@Injectable()
export class MessagingService {

  messaging: firebase.messaging.Messaging;
  messagingSupported: boolean = false;
  // currentMessage = new BehaviorSubject(null);

  constructor(
    private db: AngularFireDatabase,
    private afAuth: AngularFireAuth,
    private swReg: SwRegistrationOptions,
    private swPush: SwPush)
  {
    try {
      this.messaging = firebase.messaging();
      this.messagingSupported = true;
    }
    catch (ex) {
      console.log(ex);
      this.messagingSupported = false;
    }
  }

  updateToken(token) {
    this.afAuth.authState.pipe(take(1)).subscribe(user => {
      if (!user) {
        return;
      }
      const data = { [user.uid]: token };
      this.db.object('fcmTokens/').update(data);
    });
  }

  getPermission() {
    this.messaging
      .requestPermission()
      .then(() => {
        console.log('Notification permission granted.');
        // this.swPush.requestSubscription({
        //   serverPublicKey: environment.firebaseConfig.vapidKey
        // });
        return this.messaging.getToken();
      })
      .then(token => {
        console.log('This ia a token', token);
        this.updateToken(token);
      })
      .catch(err => {
        console.log('Unable to get permission to notify.', err);
      });
  }

  receiveMessage() {
    this.messaging.onMessage(payload => {
      console.log('Message received. ', payload);
      // this.currentMessage.next(payload);

      const { title, ...options } = payload.notification;
      navigator.serviceWorker.ready.then(registration => {
        registration.showNotification(title, options);
      });
    });
  }
}