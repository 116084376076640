import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { Routing } from './app.routing';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HomeComponent } from './components/home/home.component';
import { SpeakersComponent } from './components/speakers/speakers.component';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { InformationComponent } from './components/information/information.component';
import { MessagingService } from './services/messaging.service';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirePerformanceModule } from '@angular/fire/performance';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { UpdateService } from './services/update.service';
import { environment } from 'src/environments/environment.prod';
import { RegisterComponent } from './components/register/register.component';
import { HomeTwentyThreeComponent } from './components/homeTwentyThree/homeTwentyThree.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SpeakersComponent,
    ScheduleComponent,
    InformationComponent,
    RegisterComponent,
    HomeTwentyThreeComponent
  ],
  imports: [
    Routing,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirePerformanceModule,
    ServiceWorkerModule.register('/sw.js')
  ],
  providers: [MessagingService, UpdateService],
  bootstrap: [AppComponent]
})
export class AppModule { }
