<header class="c-header">
  <div class="c-header__inner">
    <h1>Valley Conf '24</h1>
    <h2>equipping, empowering and encouraging the local church</h2>
  </div>
</header>

<main class="c-main">
  <section [@fadeAnimation]="o.isActivated ? o.activatedRoute : ''">
    <router-outlet #o="outlet"></router-outlet>
  </section>
</main>
