import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-homeTwentyThree',
  templateUrl: './homeTwentyThree.component.html',
  styleUrls: ['./homeTwentyThree.component.scss']
})
export class HomeTwentyThreeComponent implements OnInit {

  ngOnInit() {
  }

}
