<p class="lead">Valley Conference exists to empower you to reach your God-given potential!</p>
<p>
In 2024, we are thrilled to invite you to 24 hours of world-class teaching, powerful worship and fantastic opportunities for community.</p>
<p>Hosted by Senior Pastors of Valley Church, Ed & Michele Carter and the Valley Church team, we are thrilled to also announce guest speakers Scott Wilson and Andrew Cherrie.</p>

<hr />

<article>
  <img src="/assets/Scott-Wilson-2.png" alt="Headshot of Scott Wilson" />
  <h3>Scott Wilson</h3>
  <p>Based in Denmark for over 20 years, Scott has been a leadership trainer and coach to church leaders. He is the President of Eurolead.net, a network of churches in Europe. He is also the founder and Director of the Institute for Creativity, Leadership and Management (ICLM).</p>
  
  <p>Scott travels extensively, teaching and training leaders on building great church. He holds two masters degrees, one in theology and the other in organisational development. He also holds a doctorate through an adjunct college of Liverpool University, England. He has authored eight books, is married to Linda who pastors churches in Denmark. He has two adult children.</p>
</article>

<article>
  <img src="/assets/Andrew-Cherry-1.png" alt="Headshot of Andrew Cherrie" />
  <h3>Andrew Cherrie</h3>
  <p>Andrew is the Lead Pastor at Glow UK Church, providing vision and direction. He has spent the past 20 years pastoring
  and leading in the church world with his most recent years on the leadership team at Hillsong Church, a congregation of
  over 12,000+ people in central London. Andrew has been married to Louise for over 18 years and they have five children,
  Olivia, Max, Zak, Jackson &amp; Will Franklin.</p>
</article>

<a class="o-btn" href="https://valley.churchsuite.com/events/tyqomsv8" target="_blank" rel="noopener nofollow">
  Register now!
</a>