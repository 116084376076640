import * as tslib_1 from "tslib";
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { take } from 'rxjs/operators';
import { SwPush, SwRegistrationOptions } from '@angular/service-worker';
var MessagingService = /** @class */ (function () {
    // currentMessage = new BehaviorSubject(null);
    function MessagingService(db, afAuth, swReg, swPush) {
        this.db = db;
        this.afAuth = afAuth;
        this.swReg = swReg;
        this.swPush = swPush;
        this.messagingSupported = false;
        try {
            this.messaging = firebase.messaging();
            this.messagingSupported = true;
        }
        catch (ex) {
            console.log(ex);
            this.messagingSupported = false;
        }
    }
    MessagingService.prototype.updateToken = function (token) {
        var _this = this;
        this.afAuth.authState.pipe(take(1)).subscribe(function (user) {
            var _a;
            if (!user) {
                return;
            }
            var data = (_a = {}, _a[user.uid] = token, _a);
            _this.db.object('fcmTokens/').update(data);
        });
    };
    MessagingService.prototype.getPermission = function () {
        var _this = this;
        this.messaging
            .requestPermission()
            .then(function () {
            console.log('Notification permission granted.');
            // this.swPush.requestSubscription({
            //   serverPublicKey: environment.firebaseConfig.vapidKey
            // });
            return _this.messaging.getToken();
        })
            .then(function (token) {
            console.log('This ia a token', token);
            _this.updateToken(token);
        })
            .catch(function (err) {
            console.log('Unable to get permission to notify.', err);
        });
    };
    MessagingService.prototype.receiveMessage = function () {
        this.messaging.onMessage(function (payload) {
            console.log('Message received. ', payload);
            // this.currentMessage.next(payload);
            var _a = payload.notification, title = _a.title, options = tslib_1.__rest(_a, ["title"]);
            navigator.serviceWorker.ready.then(function (registration) {
                registration.showNotification(title, options);
            });
        });
    };
    return MessagingService;
}());
export { MessagingService };
