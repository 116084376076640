import { Component, ApplicationRef, OnDestroy } from '@angular/core';
import { MessagingService } from './services/messaging.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router, NavigationEnd } from '@angular/router';
import { fadeAnimation } from './app.animations';
import { SwPush } from '@angular/service-worker';
import { UpdateService } from './services/update.service';
import { concat, interval, NEVER, Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [fadeAnimation]
})
export class AppComponent implements OnDestroy {
  title = 'Valley Conference 2022';
  cssClass: string = '';
  isAfterAnnouncement: boolean = false;

  // message;
  user: firebase.User;

  private onDestroy = new Subject<void>();
  private checkInterval = 1000 * 60 * 60 * 0.25;  // 1/4 hour

  isStandalone: boolean = window.navigator["standalone"] || window.matchMedia('(display-mode: standalone)').matches;
  needsToSeePrompt: boolean = ['iPhone', 'iPad', 'iPod'].includes(navigator.platform);
  isLocalhost: boolean = window.location.hostname === 'localhost';

  constructor(
    public afAuth: AngularFireAuth,
    public db: AngularFireDatabase,
    public router: Router,
    public messagingService: MessagingService,
    public updateService: UpdateService,
    public swPush: SwPush,
    public appRef: ApplicationRef
  ) {
    // Track GA events
    if (environment.production) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config', 'UA-34521921-6', {
            'page_path': event.urlAfterRedirects
          });
          window.scrollTo(0, 0);
          this.cssClass = event.urlAfterRedirects.replace('/', '');
        }
      });
    }

    //Check if the datetime is after the 2020 announcement
    const appIsStable = appRef.isStable.pipe(first(v => v));
    concat(appIsStable, interval(this.checkInterval))
      .pipe(
        takeUntil(this.onDestroy),
      )
      .subscribe(() => this.checkIfAfterAnnouncement());
  }

  checkIfAfterAnnouncement(): void {
    const dateNow: Date = new Date();
    const dateNowTime = dateNow.getTime();
    const dateFuture: Date = new Date(2019, 5, 29, 17, 30, 0, 0);
    const dateFutureTime = dateFuture.getTime();

    if (dateNowTime >= dateFutureTime) {
      this.isAfterAnnouncement = true;
    }
    else this.isAfterAnnouncement = false;
  }

  ngAfterViewInit(): void {
    // Show notifications if messaging is supported
    // if (this.messagingService.messagingSupported) {
    //   if (this.isStandalone || this.isLocalhost) {
    //     this.afAuth.auth.signInAnonymously();
    //     this.afAuth.auth.onAuthStateChanged(user => {
    //       this.user = user;
    //       this.messagingService.getPermission();
    //       this.messagingService.receiveMessage();
    //       // this.message = this.messagingService.currentMessage;
    //     });
    //   }
    // }
  }

  getState(outlet) {
    return outlet;
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }
}
