import { ApplicationRef, Injectable, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval, NEVER, Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';

/**
 * SwUpdatesService
 *
 * @description
 * 1. Checks for available ServiceWorker updates once instantiated.
 * 2. Re-checks every 1 hour.
 * 3. Whenever an update is available, it activates the update.
 *
 * @property
 * `updateActivated` {Observable<string>} - Emit the version hash whenever an update is activated.
 */
@Injectable()
export class UpdateService implements OnDestroy {
  private checkInterval = 1000 * 60 * 60 * 1;  // 1 hour
  private onDestroy = new Subject<void>();
  updateActivated: Observable<string>;

  constructor(appRef: ApplicationRef, private swUpdate: SwUpdate) {
    if (!swUpdate.isEnabled) {
      this.updateActivated = NEVER.pipe(takeUntil(this.onDestroy));
      return;
    }

    // Periodically check for updates (after the app is stabilized).
    const appIsStable = appRef.isStable.pipe(first(v => v));
    concat(appIsStable, interval(this.checkInterval))
      .pipe(
        takeUntil(this.onDestroy),
      )
      .subscribe(() => this.swUpdate.checkForUpdate());

    // Activate available updates.
    this.swUpdate.available
      .pipe(
        takeUntil(this.onDestroy),
      )
      .subscribe(() => {
        this.swUpdate.activateUpdate().then(() => document.location.reload());
      });

    // Notify about activated updates.
    this.updateActivated = this.swUpdate.activated.pipe(
      map(evt => evt.current.hash),
      takeUntil(this.onDestroy),
    );
  }

  ngOnDestroy() {
    this.onDestroy.next();
  }
}